/* =============================================================================
   9. FOOTER & MISCELLANEOUS
   ============================================================================= */

/* Footer */
hr {
    border: none;
    height: 4px;
    background-color: var(--primary);
    border-radius: 10px;
    margin: var(--spacing-lg) 0;
  }
  
  /* =============================================================================
     10. MISCELLANEOUS COMPONENTS & UTILITY CLASSES
     ============================================================================= */
  .primary-bg {
    background-color: rgba(72, 169, 153, 0.1);
  }
  .user-info-item {
    padding: var(--spacing-md);
  }
  .info-label {
    min-width: 150px;
    font-weight: bold;
  }
  .info-value {
    width: 33%;
  }
  
  /* Tiny button and icon styles */
  .btn-tiny {
    padding: 0.15rem 0.3rem !important;
    font-size: 0.75rem !important;
  }
  .fs-tiny {
    font-size: 10px !important;
    margin-right: 0.15rem;
  }
  