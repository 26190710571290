/* =============================================================================
   7. GRID & CONTENT COMPONENTS
   ============================================================================= */
/**
 * Grid layout for cards and content blocks.
 */
 .grid-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: var(--spacing-md);
    align-items: stretch;
  }
  .grid-item {
    padding: var(--spacing-md);
    text-align: center;
    border-radius: 0.5rem;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    background-color: var(--background-color);
    border: 2px solid #ccc;
    transition: transform 0.3s ease, background-color 0.3s ease;
    position: relative;
    overflow: hidden;
  }
  .grid-item::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, #e4ded2 30%, var(--primary) 70%);
  }
  .grid-item:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }
  