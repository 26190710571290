/* =============================================================================
   1. ROOT VARIABLES & GLOBAL RESET
   ============================================================================= */
/**
 * Define CSS custom properties that align with our SCSS theme.
 * Primary and secondary colors are set to match our SCSS definitions.
 */
 :root {
    --primary: #48a999; /* Main green shade from SCSS */
    --secondary: #388e8e; /* Darker complementary green */
    --background-color: #ffffff; /* Pure white background */
    --text-color: #2a2a2a; /* Dark gray text for readability */
  
    /* Shadows & hover effects */
    --primary-shadow-light: rgba(72, 169, 153, 0.25);
    --primary-shadow-dark: rgba(72, 169, 153, 0.5);
    --primary-hover: #3d8a7f;
  
    /* Spacing */
    --spacing-sm: 0.5rem;
    --spacing-md: 1rem;
    --spacing-lg: 2rem;
  
    /* Transition */
    --transition-fast: 0.2s ease;
    --transition-slow: 0.3s ease;
  }
  
  /* Global Reset */
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  /* Base font size for rem calculations */
  html {
    font-size: 18px;
  }
  