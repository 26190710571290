/* =============================================================================
   2. BASE TYPOGRAPHY & GLOBAL STYLES
   ============================================================================= */
/**
 * Apply a clean, modern typographic baseline using sans-serif fonts.
 */
 body {
    line-height: 1.6;
    color: var(--text-color);
    background-color: var(--background-color);
    font-family: "Poppins", sans-serif;
  }
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.2;
    margin-bottom: 0.5em;
    font-weight: 700;
  }
  
  p {
    font-size: 1rem;
    margin-bottom: 1em;
  }
  
  /* Links: inherit color with spacing and smooth transitions */
  a {
    text-decoration: none;
    color: inherit;
    padding: var(--spacing-md);
    transition: background-color var(--transition-slow),
                color var(--transition-slow);
  }
  a.active {
    background-color: rgba(72, 169, 153, 0.1);
    color: var(--primary);
    border-radius: 0.3rem;
  }
  