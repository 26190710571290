/* =============================================================================
   8. FORM ELEMENTS & SCROLLABLE CONTENT
   ============================================================================= */
   .form-control {
    border: 2px solid #e0e0e0;
    border-radius: 0.5rem;
    padding: var(--spacing-sm);
    font-size: 1rem;
    transition: border-color var(--transition-fast),
                box-shadow var(--transition-fast);
    background-color: var(--background-color);
    color: var(--text-color);
  }
  .form-control:focus {
    border-color: var(--primary);
    box-shadow: 0 0 0 0.2rem var(--primary-shadow-light);
    outline: none;
  }
  .form-label {
    font-weight: 500;
    margin-bottom: var(--spacing-sm);
    color: var(--text-color);
    display: block;
  }
  