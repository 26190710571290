/* =============================================================================
   12. SEARCH & RESOURCE PAGES
   ============================================================================= */
/* Search Form & Results */
.search-container {
    position: relative;
    width: 50%;
  }
  .search-input {
    width: 100%;
    padding-right: 2.5rem;
  }
  .search-icon {
    position: absolute;
    top: 50%;
    right: 0.75rem;
    transform: translateY(-50%);
    cursor: pointer;
    color: #6c757d;
  }
  @media (max-width: 767.98px) {
    .search-container {
      width: auto;
    }
    .search-input {
      display: none;
    }
  }
  
  /* Resource Cards */
  .resource-card {
    margin-bottom: var(--spacing-md);
  }
  .resource-card .btn {
    background-color: var(--primary);
    color: #fff;
    border: none;
  }
  .resource-card .btn:hover {
    background-color: var(--secondary);
  }
  