// _custom-properties.scss
/* =============================================================================
   CSS CUSTOM PROPERTIES (VARIABLES) FOR RUNTIME THEMING
   ============================================================================= */

:root {
  /* Light Theme */
  --primary: #{$primary-light};
  --secondary: #{$secondary-light};
  --tertiary: #{$tertiary-light};
  --footer-background-color: #{$footer-light};
  --background-color: #{$background-color-light};
  --text-color: #{$text-color-light};
  --navbar-bg-color: #{$navbar-bg-color-light};
  --navbar-text-color: #{$navbar-text-color-light};
  --navbar-text-color-hover: #{$navbar-text-color-light-hover};
  --success: #{$success-light};
  --danger: #{$danger-light};
  --warning: #{$warning-light};
  --info: #{$info-light};
  --card-bg-light: #{$card-bg-light};
  --card-text-light: #{$card-text-light};
  --card-bg-dark: #343a40; /* fallback dark card bg */
  --card-text-dark: #ffffff;
  --link-color: #{$link-color-sass};
  --link-hover-color: #{$link-hover-color-light};
  --primary-rgb: #{to-rgb($primary-light)};
}

/* Dark Theme Overrides */
.dark-theme {
  --primary: #{$primary-dark};
  --secondary: #{$secondary-dark};
  --tertiary: #{$tertiary-dark};
  --footer-background-color: #{$footer-dark};
  --background-color: #{$background-color-dark};
  --text-color: #{$text-color-dark};
  --navbar-bg-color: #{$navbar-bg-color-dark};
  --navbar-text-color: #{$navbar-text-color-dark};
  --navbar-text-color-hover: #{$navbar-text-color-dark-hover};
  --success: #{$success-dark};
  --danger: #{$danger-dark};
  --warning: #{$warning-dark};
  --info: #{$info-dark};
  --card-bg-light: #3a3a3a;
  --card-text-light: #f5f5f5;
  --card-bg-dark: #{$card-bg-dark};
  --card-text-dark: #{$card-text-dark};
  --link-color: #{$link-color-dark};
  --link-hover-color: #{$link-hover-color-dark};
  --primary-rgb: #{to-rgb($primary-dark)};
}
