/* =============================================================================
   14. RESPONSIVE & ACCESSIBILITY ENHANCEMENTS
   ============================================================================= */
   @media (max-width: 490px) {
    .head-right {
      display: none;
    }
    .logosec {
      width: 100%;
      justify-content: space-between;
    }
    .logo {
      font-size: 1.25rem;
    }
    .menuicn {
      height: 25px;
    }
    .nav-option h3 {
      font-size: 0.75rem;
    }
  }
  @media (max-width: 400px) {
    .searchbtn img {
      height: 20px;
    }
  }
  @media (max-width: 320px) {
    .searchbtn {
      width: 40px;
    }
  }
  