/* =============================================================================
   11. SPECIAL PAGE STYLES & ANIMATION
   ============================================================================= */
/* Hero / Page Banner Sections */
.hero-section,
.page-hero {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  color: #fff;
  text-align: center;
  border-radius: 8px;
  padding: 120px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.hero-section {
  min-height: 600px;
}
.page-hero {
  min-height: 500px;
  margin-bottom: var(--spacing-lg);
}

/* Fade In Animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.fade-in {
  animation: fadeIn 1s ease forwards;
}

/* Underline for Section Titles */
.section-title {
  position: relative;
  margin-bottom: 1.5rem;
  padding-bottom: 0.5rem;
}
.section-title::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 50px;
  height: 3px;
  background-color: var(--primary);
}
