// _animations.scss
/* =============================================================================
   ANIMATIONS
   ============================================================================= */
   @keyframes stripe-anim {
    25% {
      transform: translate(10px, 0) rotate(-10deg);
    }
    75% {
      transform: translateX(10px);
    }
  }
  
  @keyframes circle-anim {
    50% {
      transform: scale(0.2) rotateX(360deg) rotateY(360deg);
    }
  }
  
  @keyframes four-anim {
    50% {
      transform: scale(0.98);
    }
  }
  
  @keyframes bounce {
    0%,
    100% {
      top: 0;
    }
    50% {
      top: -20px;
    }
  }
  
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
  
  .cls-1 {
    fill: var(--primary);
  }
  .cls-2,
  .cls-3 {
    fill: var(--secondary);
  }
  
  #stripe {
    animation: stripe-anim 3s linear infinite;
    transform-origin: center;
    transform-box: fill-box;
  }
  
  .circle {
    animation: circle-anim ease infinite;
    transform-origin: center;
    transform-box: fill-box;
    perspective: 0;
  }
  
  .circle.c1 {
    animation-duration: 2s;
  }
  .circle.c2 {
    animation-duration: 3s;
  }
  .circle.c3,
  .circle.c4 {
    animation-duration: 1s;
  }
  .circle.c5 {
    animation-duration: 2s;
  }
  .circle.c6 {
    animation-duration: 3s;
  }
  
  .four,
  #ou {
    animation: four-anim cubic-bezier(0.39, 0.575, 0.565, 1) infinite;
  }
  
  .four.a {
    transform-origin: bottom left;
    animation-duration: 3s;
    transform-box: fill-box;
  }
  
  .four.b {
    transform-origin: bottom right;
    animation-duration: 3s;
    transform-box: fill-box;
  }
  
  #ou {
    animation-duration: 6s;
    transform-origin: center;
    transform-box: fill-box;
  }
  