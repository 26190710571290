// _fonts.scss
/* =============================================================================
   FONT DEFINITIONS
   ============================================================================= */

   @font-face {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    src: url("../../../src/fonts/poppins/Poppins-Regular.ttf") format("truetype");
  }
  
  @font-face {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    src: url("../../../src/fonts/poppins/Poppins-Medium.ttf") format("truetype");
  }
  
  @font-face {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    src: url("../../../src/fonts/poppins/Poppins-SemiBold.ttf") format("truetype");
  }
  
  @font-face {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    src: url("../../../src/fonts/poppins/Poppins-Bold.ttf") format("truetype");
  }
  
  @font-face {
    font-family: "Poppins";
    font-style: italic;
    font-weight: 400;
    src: url("../../../src/fonts/poppins/Poppins-Italic.ttf") format("truetype");
  }
  