// _base.scss
/* =============================================================================
   GLOBAL BASE STYLES & FONT FAMILY
   ============================================================================= */
body,
html {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  height: 100%;
  color: var(--text-color);
  background-color: var(--background-color);
  max-width: 100%;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* =============================================================================
      CUSTOM COMPONENT STYLES & LAYOUT
      ============================================================================= */

/* Typography */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  margin-bottom: 1.2rem;
  line-height: 1.3;
}

p {
  margin-bottom: 1.2rem;
  font-weight: 400;
}

/* Containers & Sections */
.container {
  max-width: 1200px;
  padding: 0 1.5rem;
}

.section {
  padding: 6rem 0;
  text-align: center;
}

/* Cards */
.card {
  background-color: var(--card-bg-light);
  color: var(--card-text-light);
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 0.5rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
  transition: background-color 0.2s ease, transform 0.2s ease;
}

.card:hover {
  transform: translateY(-0.25rem);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.card-title {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: var(--text-color);
}

.card-text {
  font-size: 1rem;
  line-height: 1.7;
  color: var(--text-color);
}

/* Buttons */
.btn {
  border: none;
  border-radius: 0.5rem;
  padding: 0.8rem 1.8rem;
  font-weight: 600;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  transition: $btn-transition;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.08);
}

.btn:focus {
  outline: none;
  box-shadow: $btn-focus-box-shadow;
}

.btn-primary {
  background-color: $primary-light;
  color: #fff;
}

.btn-primary:hover {
  background-color: $primary-light-hover;
  transform: translateY(-0.125rem);
}

.btn-primary:active {
  background-color: $primary-light-active;
  transform: translateY(0);
  box-shadow: none;
}

.btn-secondary {
  background-color: $secondary-light;
  color: #fff;
}

.btn-secondary:hover {
  background-color: $secondary-light-hover;
  transform: translateY(-0.125rem);
}

.btn-secondary:active {
  background-color: $secondary-light-active;
  transform: translateY(0);
  box-shadow: none;
}

.btn-outline-primary {
  background-color: transparent;
  border: 2px solid var(--primary);
  color: var(--primary);
}

.btn-outline-primary:hover {
  background-color: rgba(var(--primary-rgb), 0.15);
  color: var(--primary);
}

.btn-outline-primary:active {
  background-color: rgba(var(--primary-rgb), 0.3);
  color: var(--primary);
  box-shadow: none;
}

/* Form Controls */
.form-control {
  border: 2px solid #e0e0e0;
  border-radius: 0.5rem;
  padding: 0.8rem;
  font-size: 1rem;
  transition: $input-transition;
  background-color: var(--background-color);
  color: var(--text-color);
}

.form-control:focus {
  border-color: var(--input-focus-border-color);
  box-shadow: var(--input-focus-box-shadow);
  outline: none;
}

.form-label {
  font-weight: 500;
  margin-bottom: 0.5rem;
  color: var(--text-color);
  display: block;
}

/* Navbar */
.navbar {
  background-color: var(--navbar-bg-color);
  padding: 1rem 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.navbar-brand {
  color: #fff;
  font-weight: 700;
  font-size: 1.8rem;
  text-decoration: none;
}

.navbar-nav .nav-link {
  color: var(--navbar-text-color);
  font-weight: 500;
  padding: 0.5rem 1.2rem;
  transition: color 0.3s ease;
}

.navbar-nav .nav-link:hover,
.navbar-nav .nav-link.active {
  color: var(--navbar-text-color-hover);
}

.navbar-toggler {
  border: none;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: opacity 0.2s ease;
}

.navbar-toggler:hover {
  opacity: 0.7;
}

.navbar-toggler-icon {
  width: 1.5rem;
  height: 1.5rem;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='rgba(255,255,255,1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.dark-theme .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='rgba(0,0,0,1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-collapse {
  flex-grow: 1;
  justify-content: flex-end;
}

/* Footer */
footer {
  text-align: center;
  background-color: var(--background-color);
  color: var(--text-color);
  border-top: 1px solid #ddd;
}

/* Utility Classes */
.text-center {
  text-align: center;
}
.mt-5 {
  margin-top: 3rem;
}
.mb-5 {
  margin-bottom: 3rem;
}
.py-5 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

/* Dark Mode Overrides */
.dark-theme {
  background-color: var(--background-color);
  color: var(--text-color);
}

.dark-theme .card {
  background-color: var(--card-bg-dark);
  color: var(--card-text-dark);
  border-color: rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.dark-theme .form-control {
  background-color: #3b3b3b;
  border-color: #666;
  color: var(--text-color);
}

.dark-theme .form-control:focus {
  border-color: var(--primary);
  box-shadow: 0 0 0 0.2rem rgba(var(--primary-rgb), 0.25);
}

.dark-theme .navbar {
  background-color: var(--navbar-bg-color);
}
.dark-theme .navbar-brand {
  color: #fff;
}
.dark-theme .navbar-nav .nav-link {
  color: var(--navbar-text-color);
}
.dark-theme .navbar-nav .nav-link:hover,
.dark-theme .navbar-nav .nav-link.active {
  color: var(--navbar-text-color-hover);
}

.dark-theme .footer {
  background-color: var(--footer-background-color);
  color: var(--text-color);
  border-top-color: rgba(255, 255, 255, 0.1);
}

.dark-theme h1,
.dark-theme h2,
.dark-theme h3,
.dark-theme h4,
.dark-theme h5,
.dark-theme h6,
.dark-theme p {
  color: var(--text-color);
}

.dark-theme .bg-dark {
  background-color: #121212 !important;
}
.dark-theme .text-dark {
  color: #fff !important;
}
