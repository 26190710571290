// _variables.scss
/* =============================================================================
   THEME VARIABLES (SASS) - LIGHT & DARK
   ============================================================================= */

/* ----------------------------
   Light Theme Variables
----------------------------- */
$primary-light: #48a999;
$secondary-light: #388e8e;
$tertiary-light: #f9fafa;
$footer-light: #e0f2f1;
$background-color-light: #ffffff;
$text-color-light: #2a2a2a;

$navbar-bg-color-light: $primary-light;
$navbar-text-color-light: $text-color-light;
$navbar-text-color-light-hover: darken($navbar-bg-color-light, 10%);

$success-light: #4caf50;
$danger-light: #f44336;
$warning-light: #ffeb3b;
$info-light: #29b6f6;

$card-bg-light: #ffffff;
$card-text-light: $text-color-light;
$link-color-light: $primary-light;
$link-hover-color-light: darken($primary-light, 15%);

$primary-light-hover: darken($primary-light, 10%);
$primary-light-active: darken($primary-light, 20%);
$secondary-light-hover: darken($secondary-light, 10%);
$secondary-light-active: darken($secondary-light, 20%);

/* ----------------------------
   Dark Theme Variables
----------------------------- */
$primary-dark: #48a999;
$secondary-dark: #388e8e;
$tertiary-dark: #2a2a2a;
$footer-dark: #1e1e1e;
$background-color-dark: #121212;
$text-color-dark: #f5f5f5;

$navbar-bg-color-dark: $primary-dark;
$navbar-text-color-dark: $text-color-dark;
$navbar-text-color-dark-hover: lighten($navbar-bg-color-dark, 10%);

$success-dark: #66bb6a;
$danger-dark: #ef5350;
$warning-dark: #fff176;
$info-dark: #4fc3f7;

$card-bg-dark: #333333;
$card-text-dark: $text-color-dark;
$link-color-dark: $primary-dark;
$link-hover-color-dark: lighten($primary-dark, 15%);

/* ----------------------------
   Additional & Utility Variables
----------------------------- */
$primary-light-darkened: darken($primary-light, 12%);
$link-hover-decoration: none;
$pagination-active-bg: $secondary-light;
$pagination-active-border-color: $secondary-dark;

$link-color-sass: $link-color-light;
