// _bootstrap-overrides.scss
/* =============================================================================
   BOOTSTRAP OVERRIDES (SASS VARIABLE ASSIGNMENTS)
   ============================================================================= */

   $theme-colors: (
    "primary": $primary-light,
    "secondary": $secondary-light,
    "info": #29b6f6,
    "success": #4caf50,
    "warning": #ffeb3b,
    "danger": #f44336,
    "light": $tertiary-light,
    "dark": $tertiary-dark,
  );
  
  $body-bg: $background-color-light;
  $body-color: $text-color-light;
  $navbar-bg: $navbar-bg-color-light;
  $navbar-light-color: $navbar-text-color-light;
  $navbar-light-hover-color: $navbar-text-color-light-hover;
  $navbar-dark-color: $navbar-text-color-light;
  $navbar-dark-hover-color: $navbar-text-color-light-hover;
  
  $focus-color: $primary-light;
  $focus-box-shadow: 0 0 0 0.2rem rgba($primary-light, 0.25);
  
  $input-focus-color: $primary-light;
  $input-focus-border-color: $primary-light;
  $input-focus-box-shadow: 0 0 0 0.2rem rgba($primary-light, 0.25);
  $input-transition: border-color 0.2s ease, box-shadow 0.2s ease;
  
  $btn-focus-box-shadow: 0 0 0 0.2rem rgba($primary-light, 0.5);
  $btn-active-box-shadow: none;
  $btn-active-color: #fff;
  $btn-transition: background-color 0.2s ease, transform 0.1s ease;
  
  $link-transition: color 0.2s ease, text-decoration 0.2s ease;
  