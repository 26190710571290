/* =============================================================================
   4. MAIN LAYOUT & CONTENT
   ============================================================================= */
/**
 * Main container placed below the fixed header.
 */
.main-container {
  display: flex;
  width: 100vw;
  position: relative;
  top: 70px;
  z-index: 1;
}

/* Main content area with vertical scroll */
.main {
  height: calc(100vh - 70px);
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 var(--spacing-lg);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.main-content {
  flex: 1; /* Pushes the footer down */
}

/* =============================================================================
   5. SCROLLBAR CUSTOMIZATION
   ============================================================================= */
/**
 * Modernize the scrollbar appearance for the main content.
 */
.main::-webkit-scrollbar {
  width: 5px;
}
.main::-webkit-scrollbar-track {
  background-color: #9e9e9e;
}
.main::-webkit-scrollbar-thumb {
  background-image: linear-gradient(to bottom, #003333, #002020);
}

/* Display Picture Container */
.dp {
  height: 40px;
  width: 40px;
  background-color: #626262;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.dpicn {
  height: 42px;
}
