/* =============================================================================
   13. CONTROLS, PAGINATION & TABLES
   ============================================================================= */
/* Controls Container */
.controls-container {
    display: flex;
    flex-wrap: wrap;
    gap: var(--spacing-md);
    align-items: center;
    justify-content: space-between;
    margin: var(--spacing-md) 0;
  }
  .controls-left,
  .controls-right {
    display: flex;
    gap: var(--spacing-sm);
    align-items: center;
  }
  .pagination-controls {
    display: flex;
    gap: 0.25rem;
  }
  @media (max-width: 768px) {
    .controls-container {
      flex-direction: column;
      align-items: stretch;
      gap: var(--spacing-md);
    }
    .controls-left {
      flex-direction: column;
      gap: var(--spacing-md);
    }
    .pagination-controls {
      justify-content: center;
    }
    .controls-right {
      justify-content: center;
      text-align: center;
    }
  }
  @media (max-width: 576px) {
    .pagination-controls {
      flex-wrap: wrap;
      justify-content: center;
    }
    .pagination-controls .btn {
      flex: 1 1 45%;
      margin: 0.25rem;
    }
  }
  
  /* Tables */
  table {
    width: 100%;
    border-collapse: collapse;
  }
  table tr:nth-child(even) {
    background-color: #c5cae95b;
  }
  table tr:nth-child(odd) {
    background-color: #99999975;
  }
  table th,
  table td {
    padding: var(--spacing-md);
    text-align: left;
    border: 1px solid #000;
  }
  