/* =============================================================================
   3. HEADER & NAVIGATION
   ============================================================================= */
/**
 * Fixed header with a subtle shadow and balanced spacing.
 */
 header {
    height: 70px;
    width: 100vw;
    padding: 0 var(--spacing-md);
    background-color: var(--background-color);
    color: var(--text-color);
    position: fixed;
    z-index: 100;
    box-shadow: 0 2px 10px rgba(161, 182, 253, 0.6);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  /* Header Left: Logo & Menu Toggle */
  .header-left {
    display: flex;
    align-items: center;
  }
  
  .logo {
    color: var(--primary);
    font-size: 1.7rem;
    font-weight: 600;
  }
  
  .icn {
    height: 20px;
  }
  
  .menuicn {
    cursor: pointer;
    margin-left: var(--spacing-sm);
  }
  
  /* Header Right: Profile/Notifications */
  .head-right,
  .logosec {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  /* Notification Indicator */
  .circle {
    height: 10px;
    width: 10px;
    position: absolute;
    background-color: var(--secondary);
    border-radius: 50%;
    left: 30px;
    top: 8px;
  }
  